<template>
  <div class="menu">
    <div>
      <div class="menu__logo">
        <img :src="logo" alt="">
      </div>
      <a-menu mode="inline"
              :default-selected-keys="[`${defaultSelectedMenuItem}`]"
              v-model="selectedKeys"
              style="border-right: none; width: 100%"
      >
        <a-menu-item key="DashboardInformation">
          <a-icon type="dashboard" />
          Dashboard
        </a-menu-item>
        <a-menu-item key="Bookings">
          <a-icon type="book" />
          Boekingen
        </a-menu-item>
        <a-sub-menu key="Warehouse" v-if="rootState.userInfo.hasCompany && rootState.userInfo.createdAt">
          <span slot="title">
            <a-icon type="bank"/>
            <span>Warehouse</span>
          </span>
          <a-menu-item key="WarehouseStore">
            Magazijn
          </a-menu-item>
          <a-menu-item key="WarehouseShipped">
            Verzonden
          </a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="Settings">
          <span slot="title">
            <a-icon type="setting"/>
            <span>Instellingen</span>
          </span>
          <a-menu-item key="PersonalInformation">
            Persoonlijke informatie
          </a-menu-item>
          <a-menu-item key="AccountInformation">
            Account
          </a-menu-item>
          <a-menu-item key="CreateCompany" v-if="!rootState.userInfo.hasCompany">
            Voltooi registratie
          </a-menu-item>
          <a-menu-item key="DocumentsInformation" v-if="rootState.userInfo.hasCompany">
            Documenten
          </a-menu-item>
          <a-menu-item key="CompanyInformation" v-if="rootState.userInfo.hasCompany">
            Bedrijfsgegevens
          </a-menu-item>
        </a-sub-menu>
        <a-menu-item key="Logout" @click="$emit('logout')">
          <a-icon type="export" />
          Uitloggen
        </a-menu-item>
      </a-menu>
    </div>
    <div
      class="makeBooking"
      @click="$route.name !== 'Calculator' ? $router.push({name: 'Calculator'}) : null"
      style="cursor: pointer"
    >
      <img :src="kosten" alt="kosten">
      <p>
        kosten calculator
      </p>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/Logo-horizontaal.png'
import kosten from '@/assets/dashboard/kosten.svg'
import {mapState} from "vuex";
import { Menu } from 'ant-design-vue';

export default {
  name: "Menu",
  components: {
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ASubMenu: Menu.SubMenu,
  },
  data() {
    return {
      logo,
      kosten,
      defaultSelectedMenuItem: 'DashboardInformation',
    }
  },
  computed: {
    ...mapState({
      rootState: state => state
    }),
    selectedKeys: {
      get: function () {
        let arr = [];
        arr.push(this.$route.name)
        return arr
      },
      set: function (newValue) {
        if (newValue[0] === 'Logout') return
        this.handleClick(newValue)
        return newValue
      }
    }
  },
  methods: {
    handleClick(e) {
      if (this.$route.name !== e[0]) {
        this.$router.push({ name: e[0] })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .menu {
    width: 300px;
    display: flex;
    position: fixed;
    top: 30px;
    left: 0;
    bottom: 0;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: hidden;
    padding-right: 7px;

  }

  .menu__logo {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: 24px;
    margin-bottom: 20px;
    img {
      width: 245px;
      height: 50px;
    }
  }
  .makeBooking {
    padding: 16px;
    margin: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    background: rgba(#FFA300, 0.07);
    border-radius: 8px;
    img {
      width: 100px;
      height: 100px;
    }
    p {
      margin-bottom: 0;
      font-size: 20px;
      font-weight: 500;
      color: black;
    }
  }
</style>
