<template>
  <div class="dashboard">
    <header class="dashboard__header">
      <div>
        <p v-if="rootState.userInfo.name">
          hallo {{ rootState.userInfo.name || 'visitor' }}
        </p>
        <img v-if="rootState.userInfo.photoUrl"
             :src="rootState.userInfo.photoUrl"
             alt="avatar"
        >
        <svg
          v-else
          class="avatarAlt"
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
          style="width: 40px"
        >
          <path
            d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
        </svg>
      </div>
    </header>
    <div class="dashboard__content">
      <div>
        <router-view>

        </router-view>
      </div>
    </div>
    <Menu @logout="logoutHandler" class="dashboard__nav"/>
    <div class="dashboard__footer">
    </div>
  </div>
</template>

<script>

import {mapActions, mapState} from "vuex";
import Menu from "@/components/dashboard/Menu";
import NotificationModal from "@/components/dashboard/NotificationModal";

export default {
  name: 'Dashboard',
  components: {
    Menu,
  },
  created() {
    this.getUser().then(res => {
      if (!res.ok) {
        this.$router.push('/')
      }
    }).then(() => this.getCompanyInfo())
    // this.checkIsNeedOpenModal()
  },
  methods: {
    ...mapActions([
      'getUser',
      'getCompanyInfo',
      'logout',
    ]),
    // checkIsNeedOpenModal() {
    //   if (!localStorage.getItem('isNotificationShow') || localStorage.getItem('isNotificationShow') === 'true') {
    //     localStorage.setItem('isNotificationShow', 'true')
    //   }
    // },
    logoutHandler() {
      this.logout().then(res => {
        this.$router.push('/')
      })
    }
  },
  computed: {
    ...mapState({
      rootState: state => state
    }),
  }
}
</script>
<style scoped lang="scss">
.dashboard {
  display: grid;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  grid-template:
      [header-left] 'nav head' 72px [header-right]
      [main-left] 'nav  main' 1fr [main-right]
      [footer-left] 'nav  foot' 50px [footer-right] / 300px 1fr
}

.dashboard__header {
  grid-area: head;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px 0 0;
  position: fixed;
  left: 300px;
  right: 0;
  background: white;
  z-index: 999;
  height: 72px;

  & > div {
    display: flex;
    align-items: center;
    height: 100%;
  }
  p {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.7;
    color: #263238;
    margin-right: 12px;
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: contain;
  }
}

.dashboard__footer {
  grid-area: foot;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 255, 0.02);
  a {
    padding: 0 20px;
  }

}

.dashboard__content {
  grid-area: main;
  padding: 30px 30px 0 30px;
  background-color: rgba(0, 0, 255, 0.02);
  position: relative;
  & > div {
    height: 100%;
  }
}

.dashboard__nav {
  grid-area: nav;
}
</style>
